import Vue from 'vue'
import _mixin from '../commons/_mixin';

Vue.mixin({
  data: () => (Object.assign(_mixin.data, {
    
  })),
  computed: Object.assign(_mixin.computed, {
    schedules: {
      get() { return this.appData.schedules },
      set(newSchedules) { this.appData = _.assign(this.appData, {schedules: newSchedules}) },
    },
    schedule: {
      get() { return _.cloneDeep(this.$store.state.schedule) },
      set(newSchedule) { this.$store.commit('setSchedule', newSchedule) },
    },
    volume: {
      get() { return this.appData.volume },
      set(newVolume) { this.appData = _.assign(this.appData, {volume: newVolume}) }
    },
    nowMsec() {
      return this.$store.state.nowMsec
    },
    nowSec() {
      return this.$store.state.nowSec
    },
    settingDialog: {
      get() { return this.$store.state.settingDialog },
      set(bool) { this.$store.commit('setSettingDialog', bool) },
    },
    nextTimestamp() {
      let timestamps = []
      const now = dayjs(this.nowSec)
      const today = now.day()
      _.forEach(this.schedules, schedule => {
        if (schedule.toggle) {
          _.forEach(_.range(7), addDay => {
            if (schedule.days[(today + addDay) % 6]) {
              _.forEach(schedule.hours, (isActive, hour) => {
                if (
                  isActive
                  && (
                    addDay > 0
                    || hour > now.hour()
                    || (hour === now.hour() && schedule.minutes > now.minute())
                    || (hour === now.hour() && schedule.minutes === now.minute() && now.second() < 5)
                  )
                ) {
                  timestamps.push(dayjs().startOf('day').add(addDay, 'days').add(hour, 'hours').add(schedule.minutes, 'minutes').valueOf())
                }
              })
            }
          })
        }
      })
      return _.sortBy(_.uniq(timestamps))[0]
    },
    timeRemain() {
      return _.round((this.nextTimestamp - this.nowMsec) / 1000)
    },
  }),

  methods: Object.assign(_mixin.methods, {
    twoDigit(num) {
      return _.toString(num).padStart(2, '0')
    }
  }),
})
