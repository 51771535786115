<template>
  <fragment>
    <v-dialog
      v-model="dialog"
      persistent
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-icon
            color="primary"
          >mdi-volume-high</v-icon>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="stop()"
          >{{ $t('stop') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <audio id="bell" :src="require('../assets/bell-jp.mp3')"></audio>
  </fragment>
</template>

<script>
  export default {
    data() {
      return {
        name: 'homePlayer',
        dialog: false,
        audio: null,
        isStoped: false,
        isPlaying: false,
      };
    },
    computed: {},
    mounted() {
      this.audio = document.getElementById('bell')
      this.audio.volume = this.volume / 100
    },
    watch: {
      async timeRemain() {
        if (this.timeRemain <= 0) {
          if (! this.isStoped) {
            try {
              await this.audio.play()
              this.isPlaying = true
              this.dialog = true
              setTimeout(() => {
                this.dialog = false
                this.isPlaying = true
              }, 24 * 1000)
            } catch(err) {
              console.log('fail', err)
            }
          }
        }
      },
      volume() {
        this.audio.volume = this.volume / 100
      }
    },
    methods: {
      async stop() {
        this.isStoped = true
        this.isPlaying = false
        this.audio.pause()
        this.audio.currentTime = 0
        this.dialog = false
        setTimeout(() => {
          this.isStoped = false
        }, 30 * 1000)
      }
    },
    i18n: {
      messages: {
        ja: {
          stop: '再生を停止する'
        },
        en: {
          stop: 'Stop'
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .v-dialog {
    .v-card__text {
      text-align: center;
      padding-bottom: 0;
      .v-icon {
        font-size: 100px;
      }
    }
  }
</style>
