<template>
  <v-list-item
    @click="schedule = item"
  >
    <v-list-item-content>
      <v-card>
        <div class="left">
          <v-card-title>{{ item.label }}</v-card-title>
          <v-card-text class="hours">
            <span
              v-for="hour in hours"
              :key="hour"
              class="hour circle"
              :class="{active: item.toggle}"
            >{{ hour }}</span>
          </v-card-text>
        </div>
        <div
          class="right"
          :class="{active: item.toggle}"
        >
          <div class="minutes">{{ item.minutes }}</div>
        </div>
      </v-card>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    props: ['item'],
    data() {
      return {
        name: 'schedule',
      };
    },
    computed: {
      hours() {
        const hoursSets = []
        _.forEach(this.item.hours, (hour, i) => {
          if (hour) {
            if (_.last(hoursSets) && _.last(_.last(hoursSets)) === i - 1) {
              _.last(hoursSets).push(i)
            } else {
              hoursSets.push([i])
            }
          }
        })
        return _.map(hoursSets, set => { return set.length > 1 ? _.first(set) + ' - ' + _.last(set) : _.first(set); })
      }
    },
    mounted() {
    },
    methods: {
    },
    i18n: {
      messages: {
        ja: {
        },
        en: {
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .v-list-item__content {
    padding: 8px 0;
  }
  .v-card {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .left {
      width: calc(100% - 80px);
    }
    .right {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 40px;
      background-color: #999;
      &.active {
        background-color: var(--v-primaryBG-base);
      }
    }
    .v-card__title {
      padding: 8px 16px;
    }
    .hours {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 8px;
    }
    .hour {
      width: auto;
      border-radius: 18px;
      padding: 0 13px;
    }
  }
  ::v-deep .theme--dark {
    .right {
      background-color: #444;
    }
  }
</style>
