<template>
  <v-btn
    bottom
    right
    fab
    fixed
    :x-large="bodyWidth > 700 || bodyHeight > 700"
    color="secondary"
    @click="add()"
  ><v-icon>mdi-plus</v-icon></v-btn>
</template>

<script>
  import INIT from '../init.js'
  export default {
    data() {
      return {
        name: 'homeAdd',
      };
    },
    methods: {
      add() {
        const lastId = _.get(_.maxBy(this.schedules, 'id'), 'id') || 0
        const newSchedule = _.assign(INIT.SCHEDULE, {
          id: lastId + 1,
          label: this.$t('label') + (lastId + 1)
        })
        this.$store.commit('setSchedule', newSchedule)
      }
    },
    i18n: {
      messages: {
        ja: {
          label: 'チャイム ',
        },
        en: {
          label: 'Bell ',
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .v-btn {
    z-index: 6;
    bottom: min(22vmin, 140px);
    right: 5vw;
  }
</style>
