<template>
  <v-list-item-action>
    <v-switch v-model="toggle"></v-switch>
  </v-list-item-action>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    computed: {
      toggle: {
        get() { return _.get(this.schedule, 'toggle') || false },
        set(bool) { this.schedule = _.assign(this.schedule, {toggle: bool}) },
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
