<template>
  <v-dialog
    v-model="dialog"
    width="300"
  >
    <v-card>
      <v-card-title>{{ $t('title') }}</v-card-title>
      <v-card-text>{{ $t('desc') }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="reload"
        >{{ $t('retry') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { Plugins } from '@capacitor/core'
  const { Network } = Plugins

  export default{
    data() {
      return {
        dialog: false
      }
    },
    async mounted() {
      const networkStatus = await Network.getStatus()
      if (! _.get(networkStatus, 'connected')) {
        this.dialog = true
      }
    },
    methods: {
      reload() {
        location.reload()
      },
    },
    i18n: {
      messages: {
        ja: {
          title: 'ネットワークエラー',
          desc: 'ネットワーク接続を確認してください',
          retry: '再接続する'
        },
        en: {
          title: 'NETWORK ERROR',
          desc: 'Check your network connection.',
          retry: 'RETRY'
        }
      }
    }
  }
</script>
