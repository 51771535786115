import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _i18n from '../commons/_i18n'
import _mixin from '../commons/_mixin'

Vue.use(VueI18n)

export default new VueI18n({
  locale: _mixin.computed.lang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {
    ja: Object.assign(_i18n.ja, {
      appName: 'スクールチャイム',
      appTitle: 'スクールチャイム | 学校の鐘（ベル）を鳴らすアラームアプリです',
      appDesc: '学校の鐘を鳴らすアラームアプリ',
    }),
    en: Object.assign(_i18n.en, {
      appName: 'School Bell',
      appTitle: 'School Bell | An alarm app that rings the school bell',
      appDesc: 'An alarm app that rings the school bell',
    }),
  }
})
