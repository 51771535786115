<template>
  <fragment>
    
    <!-- Form -->
    <v-text-field
      filled
      v-model="form.name"
      :label="$t('name')"
      v-if="pageType === 'contact'"
    ></v-text-field>
    <v-text-field
      filled
      v-model="form.email"
      :label="$t('email')"
      v-if="pageType === 'contact'"
    ></v-text-field>
    <v-textarea
      filled
      :label="$t('comment')"
      v-model="form.content"
    ></v-textarea>
    <v-btn
      color="primary"
      :disabled="isSending"
      @click="send"
    >{{ $t('dialog.send') }}</v-btn>
    
    <!-- Loading -->
    <v-overlay :value="isSending">
      <v-progress-circular
        indeterminate
        color="white"
      ></v-progress-circular>
    </v-overlay>
    
    <!-- dialog -->
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text v-html="isSuccess ? $t('result.done') : $t('result.fail')"></v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="dialog = false"
          >{{ $t('dialog.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </fragment>
</template>

<script>
  import INIT from '../../init.js'

  // フォーム初期データ
  class Form {
    constructor() {
      this.name = '',
      this.email = '',
      this.content = ''
    }
  }
  export default{
    props: ['pageType'],
    data() {
      return {
        form: new Form(),
        isSending: false,
        isSuccess: false,
        dialog: false,
      };
    },
    methods: {
      async send() {
        this.isSending = true
        const url = 'https://apps.wanakijiji.com/wp-json/wp/v2/comments/'
        const data = {
          post: INIT.POST_ID,
          author_name: this.form.name || this.userAgent,
          author_email: this.form.email,
          content: this.$t('title.' + this.pageType) + '\n\n' + this.form.content + '\n\n' + navigator.userAgent,
        }
        const res = await axios.post(url, data).catch((error) => { console.log(error) })
        if (res) {
          // 送信成功
          this.form = new Form()
          this.isSuccess = true
        } else {
          // 送信失敗
          this.isSuccess = false
        }
        this.isSending = false
        this.dialog = true
        setTimeout(() => {
          this.dialog = false
        }, 5000)
      },
    },
    i18n: {
      messages: {
        ja: {
          result: {
            done: '送信されました。<br>お問い合わせありがとうございました。',
            fail: '送信に失敗しました。<br>しばらくたってから再度送信して下さい。',
          },
          name: 'お名前',
          email: 'メールアドレス',
          comment: '内容',
          notValid: 'メールアドレスが不完全です',
          required: '必須',
        },
        en: {
          result: {
            done: 'Your message was sent successfully.',
            fail: 'Failed to send your message.',
          },
          name: 'Name',
          email: 'Email Address',
          comment: 'Comments',
          notValid: 'Not valid email',
          required: 'required',
        }
      }
    },
  };
</script>
<style scoped>
</style>