<template>
  <v-list-item-action>
    <span
      v-for="day in optionDays"
      :key="day"
      class="circle"
      :class="{active: days[day]}"
      @click="toggle(day)"
    >{{ INIT.WEEKS[day] }}</span>
  </v-list-item-action>
</template>

<script>

  export default {
    data() {
      return {
        optionDays: _.range(7),
      };
    },

    computed: {
      days() {
        return _.get(this.schedule, 'days') || []
      }
    },

    methods: {
      toggle(day) {
        this.schedule.days[day] = ! this.schedule.days[day]
        this.schedule = _.cloneDeep(this.schedule) // reactive
      },
    },

    i18n: {
      messages: {
        ja: {
        },
        en: {
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .v-list-item__action {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0 !important;
    width: calc(100% - 80px);
  }
  .circle {
    width: 32px;
    height: 32px;
    font-size: 1rem;
    margin: 2px;
  }
  @media (min-width: 410px) {
    .circle {
      cursor: pointer;
      width: min(50px, 8.6vw);
      height: min(50px, 8.6vw);
      font-size: 1.1rem;
      margin: 3px;
    }
  }
</style>
