// common mixin
import INIT from '../init'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import "@capacitor-community/firebase-analytics"
import { Capacitor, Plugins } from '@capacitor/core'
import { AdSize, AdPosition } from '@capacitor-community/admob'
const { Geolocation, AdMob, Browser, FirebaseAnalytics } = Plugins

// firebase
if (Capacitor.platform === 'web') {
  FirebaseAnalytics.initializeFirebase(INIT.FIREBASE)
}

export default {
  data: {
    INIT: INIT,
  },
  computed: {
    // ライブラリ
    _() { return _ },
    dayjs() { return dayjs },
    // platform
    platform() { return Capacitor.platform },
    // user agent
    userAgent() {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('iphone') !== -1 || ua.indexOf('ipod') !== -1 || ua.indexOf('ipad') !== -1) {
        return 'ios'
      } else if (ua.indexOf('android') !== -1) {
        return 'android'
      } else {
        return 'others'
      }
    },
    // タスクバー高さ
    appBarHeight() { return this.$store.state.appBarHeight },
    // 画面サイズ
    bodyWidth() { return this.$store.state.bodyWidth },
    bodyHeight() { return this.$store.state.bodyHeight },
    // 大きなスクリーン
    isLargeScreen() { return this.bodyWidth >= 1264 },
		// スクロール量
    scrollTop() { return this.$store.state.scrollTop },
		// タブ番号
		tabId: {
			get() { return this.$store.state.tabId },
			set(newValue) { this.$store.commit('setTabId', newValue) }
		},
		// 更新ステータス
		pullto: {
			get() { return this.$store.state.pullto },
			set(status) { this.$store.commit('setPullto', status) }
		},
    // 言語
    lang() {
      if (location.protocol === 'https:' || location.protocol === 'http:') {
        // PWA（日本語ファースト）
        return (_.includes(location.pathname, '/en/')) ? 'en' : 'ja'
      } else {
        // WevView
        return (navigator.language.match(/ja/)) ? 'ja' : 'en'
      }
    },
    // 日本語判定
    isJa() { return (this.lang === 'ja') },
    // 全保存データ
    storage: {
      get() { return _.cloneDeep(this.$store.state.storage) },
      set(newData) { this.$store.commit('setStorage', newData) },
    },
    // アプリ個別保存データ
    appData: {
      get() { return this.storage.data },
      set(newAppData) { this.storage = _.assign(this.storage, {data: newAppData}) },
    },
    // テーマ
    theme: {
      get() { return this.appData.theme },
      set(newTheme) { this.appData = _.assign(this.appData, {theme: newTheme}) }
    },
  },

  methods: {
    // 地図描画
    drawMap(options) {
      let map = {};
			options = Object.assign({
				id: 'map',
				zoom: 15,
				center: {lat: 35.7052579, lng: 139.752536},
				attributionControl: false,
				zoomControl: true,
			}, options);
			map = L.map(document.getElementById(options.id), options);
			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 18
				}
			).addTo(map);
      return map;
    },
    // 現在置取得
    async getLocation() {
      const location = await Geolocation.getCurrentPosition()
      return _.get(location, 'coords') ? { lat: location.coords.latitude, lng: location.coords.longitude } : null;
    },
    // 現在値追跡
    watchLocation(callback) {
      Geolocation.watchPosition({}, (location, err) => {
        if (_.get(location, 'coords')) {
          callback({ lat: location.coords.latitude, lng: location.coords.longitude })
        }
        if (err) { console.log(err) }
      })
    },
    // admob
    admobShow() {
      if (Capacitor.platform !== 'web') {
        AdMob.initialize()
        const options = {
          adId: INIT.API_KEYS.admob.android,
          adSize: AdSize.BANNER,
          position: AdPosition.BOTTOM_CENTER,
          margin: 0,
          isTesting: true,
          npa: true,
        };
        AdMob.showBanner(options)
      }
    },
    admobHide() {
      if (Capacitor.platform !== 'web') {
        AdMob.removeBanner()
      }
    },
    // firebase analytics
    firebaseSetScreenName(screenName) {
      if (Capacitor.platform !== 'web') {
        FirebaseAnalytics.setScreenName({ screenName: screenName })
      }
    },
    // browser
    windowOpen(url) {
      Browser.open({ url: url })
    },
  },
}
