<template>
    <v-list-item-action>
      <v-text-field
        v-model="label"
      ></v-text-field>
    </v-list-item-action>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    computed: {
      label: {
        get() { return _.get(this.schedule, 'label') || '' },
        set(label) { this.schedule = _.assign(this.schedule, {label: label}) },
      }
    },
  };
</script>

<style lang="scss" scoped>
  .v-input {
    ::v-deep .v-input__slot:before {
      border: none !important;
    }
    ::v-deep input {
      text-align: right;
    }
    ::v-deep .v-text-field__details {
      display: none;
    }
  }
  @media (min-width: 410px) {
    .v-input {
      ::v-deep input {
        cursor: pointer;
        max-height: none;
        font-size: 1.5rem;
      }
    }
  }
</style>
