import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import INIT from '../init.js'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: INIT.COLOR.light,
      dark: INIT.COLOR.dark
    },
    dark: false,
    options: {
      customProperties: true, // css内でcolorを呼び出せる
    }
  }
});
