import Vue from 'vue'
import Vuex from 'vuex'
import _store from '../commons/_store';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  namespaced: true,
  state: Object.assign(_store.state, {
    nowMsec: 0,
    nowSec: 0,
    schedule: null,
    settingDialog: false,
  }),
  mutations: Object.assign(_store.mutations, {
		setNowMsec(state, nowMsec) {
			state.nowMsec = nowMsec
		},
		setNowSec(state, nowSec) {
			state.nowSec = nowSec
		},
		setSchedule(state, schedule) {
			state.schedule = schedule
		},
		setSettingDialog(state, bool) {
			state.settingDialog = bool
		},
  }),
})
