<template>
  <fragment>
    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title>{{ $t('title') }}</v-card-title>
        <v-card-text>
          <span>{{ $t('desc1') }}</span>
          <br>
          <span v-if="installEvent && isJa">{{ $t('desc2') }}</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            dark
            x-large
            color="primaryBG"
            @click="dialog = false"
          >{{ $t('allow') }}</v-btn>
        </v-card-actions>
        <v-card-actions v-if="installEvent">
          <v-btn
            block
            dark
            x-large
            color="primaryBG"
            @click="install()"
          >{{ $t('install') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <audio id="silent" :src="require('../assets/silent.mp3')"></audio>
  </fragment>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false,
        installEvent: null,
      };
    },
    computed: {},
    async mounted() {
      // installEventの保存
      if (this.userAgent === 'others') { // windows or mac
        window.addEventListener('beforeinstallprompt', (event) => {
          event.preventDefault()
          this.installEvent = event
        })
      }
      // 無音再生
      const silent = document.getElementById('silent')
      try {
        await silent.play()
      } catch(err) {
        console.log('fail', err)
        this.dialog = true
      }
    },
    watch: {},
    methods: {
      install() {
        this.installEvent.prompt()
        this.dialog = false
      }
    },
    i18n: {
      messages: {
        ja: {
          title: '自動再生の許可',
          desc1: 'このアプリにオーディオの自動再生を許可しますか？',
          desc2: 'アプリとしてインストールすると、毎回「許可」をしなくても自動再生されるようになります。',
          allow: '許可する',
          install: 'インストールする',
        },
        en: {
          title: 'Allow autoplay',
          desc1: 'Do you want to allow this app to play audio automatically?',
          desc2: 'If you install it as an app, it will be played automatically without "permission" every time.',
          allow: 'allow',
          install: 'install',
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .v-card {
    padding-bottom: 10px;
  }
</style>
