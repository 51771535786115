// common router
// lazy-loadを使うとvuetifyでエラー
import Home from '../components/Home.vue'
import Contact from '../commons/components/Contact.vue'
import Request from '../commons/components/Request.vue'

export default [{
  path: '/',
  name: 'home',
  component: Home
}, {
  path: '/contact',
  name: 'contact',
  component: Contact
}, {
  path: '/request',
  name: 'request',
  component: Request
}, {
  path: '/en/',
  name: 'home-en',
  component: Home
}, {
  path: '/en/contact',
  name: 'contact-en',
  component: Contact
}, {
  path: '/en/request',
  name: 'request-en',
  component: Request
}]