// global
import 'lodash'
import dayjs from 'dayjs'
import axios from 'axios'
window.dayjs = dayjs
window.axios = axios

// import
import Vue from 'vue'
import App from './App.vue'
import router from './settings/router'
import store from './settings/store'
import vuetify from './settings/vuetify'
import i18n from './settings/i18n'
import INIT from './init.js'
import './settings/mixin'
import './registerServiceWorker'

// plugins
import { Plugins } from '@capacitor/core'
const { StatusBar } = Plugins

// fragment（enable multi root component）
// vue-fragment@1.5.1
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  created() {
    if (this.platform !== 'web') {
      StatusBar.setBackgroundColor({
        color: INIT.COLOR.light.statusBar
      });
    }
    // resize
    window.addEventListener('resize', _.debounce(() => {
      this.$store.commit('renewSize');
    }, 200));
    // scroll
    window.addEventListener('scroll', _.debounce(() => {
      this.$store.commit('renewScrollTop');
    }, 200));
    // storage
    let storage;
    if (localStorage.getItem(INIT.APP_ID)) {
      try {
        storage = JSON.parse(localStorage.getItem(INIT.APP_ID))
      } catch(err){
        storage = INIT.LOCAL_DATA_STRUCTURE
        console.log(err)
      }
    } else {
      storage = INIT.LOCAL_DATA_STRUCTURE
    }
    this.$store.commit('setStorage', storage)
    // now timestamp
    setInterval(() => {
      this.$store.commit('setNowMsec', dayjs().valueOf())
    }, 250)
    setInterval(() => {
      this.$store.commit('setNowSec', dayjs().valueOf())
    }, 20 * 1000)
    this.$store.commit('setNowSec', dayjs().valueOf())
    // title
    document.title = this.$t('appName')
    // update
    if (! this.appData.volume) {
      this.appData = _.assign(this.appData, {volume: 80, theme: 'system'})
    }
  },
  async mounted() {
    // admob
    // this.admobShow()
  },
  watch: {
    // vuetify theme
    appData() {
      // vuetify.framework.theme.dark = false
      vuetify.framework.theme.dark = (
        this.appData.theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches
        || this.appData.theme === 'dark'
      )
    }
  }
}).$mount('#app')

Vue.config.productionTip = false

