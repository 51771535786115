<template>
  <fragment>
    <v-btn
      text
      color="warning"
      @click="dialog = true"
    >{{ $t('dialog.delete') }}</v-btn>
    
    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>{{ $t('confirm') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialog = false"
          >{{ $t('dialog.no') }}</v-btn>
          <v-btn
            text
            color="warning"
            @click="deleteItem()"
          >{{ $t('dialog.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false
      };
    },
    computed: {
    },
    methods: {
      deleteItem() {
        const scheduleIndex = _.findIndex(this.schedules, ['id', this.schedule.id])
        if (scheduleIndex !== -1) {
          this.schedules.splice(scheduleIndex, 1)
          this.schedules = _.cloneDeep(this.schedules)
        }
        this.dialog = false
        this.schedule = null
      }
    },
    i18n: {
      messages: {
        ja: {
          confirm: 'このスケジュールを削除しますか？',
        },
        en: {
          confirm: 'Are you sure you want to delete this schedule?',
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
