<template>
  <fragment>
    <v-list-item-action @click="dialog = true">
      {{ minutes }}
    </v-list-item-action>
    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="min">{{ timeMin }}</div>
          <v-time-picker
            v-model="time"
            format="24hr"
            color="primary"
            @change="dialog = false"
          ></v-time-picker>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            color="primary"
            @click="dialog = false"
          >
            {{ $t('dialog.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>

  export default {
    props: ['labelName'],
    data() {
      return {
        time: '00:00',
        dialog: false,
      };
    },

    computed: {
      timeMin() {
        return this.time.split(':')[1]
      },
      minutes: {
        get() { return _.get(this.schedule, 'minutes') || 0 },
        set(minutes) { this.schedule = _.assign(this.schedule, {minutes: minutes}) },
      },
    },

    watch: {
      dialog() {
        if (this.dialog) {
          // open
          this.time = '00:' + _.toString(this.minutes).padStart(2, '0')
          setTimeout(() => {
            document.querySelector('.v-picker__title__btn:last-child').click()
          }, 0)
        } else {
          // close
          this.minutes = _.toNumber(this.timeMin)
        }
      }
    },

    methods: {},

    i18n: {
      messages: {
        ja: {},
        en: {}
      }
    },
  };
</script>

<style lang="scss" scoped>
  .v-list-item__action {
    font-size: 1.5rem;
    padding-left: 200px;
  }
  @media (min-width: 410px) {
    .v-list-item__action {
      font-size: 2rem;
    }
  }
  .v-picker {
    ::v-deep .v-picker__body {
      background-color: transparent;
    }
    ::v-deep .v-picker__title {
      display: none;
    }
    ::v-deep .v-time-picker-clock__container {
      padding: 0 10px 0 0;
    }
  }
  .v-card__text {
    line-height: 1em;
    font-size: 80px;
    text-align: center;
    .min {
      color: var(--v-primary-base);
      margin-bottom: 20px;
    }
  }
</style>
