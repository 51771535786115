<template>
  <div class="timer">
    <span :style="{opacity: sign ? 1 : 0}">{{ sign || '-' }}</span>
    {{ time }}
  </div>
</template>

<script>
  export default {
    data() {
      return {
        name: 'homeTimer',
      };
    },
    computed: {
      time() {
        if (this.timeRemain || this.timeRemain === 0) {
          if (this.timeRemain > (100 * 60 * 60)) {
            return '99:99:99'
          } else if (this.timeRemain <= 0) {
            return '00:00:' + this.twoDigit(Math.abs(this.timeRemain))
          } else {
            const hour = _.floor(this.timeRemain / (60 * 60))
            const minute = _.floor((this.timeRemain - (hour * 60 * 60)) / 60)
            const second = this.timeRemain - (hour * 60 * 60) - (minute * 60)
            return this.twoDigit(hour) + ':' + this.twoDigit(minute) + ':' + this.twoDigit(second)
          }
        } else {
          return '-- : -- : --'
        }
      },
      sign() {
        if (this.timeRemain) {
          if (this.timeRemain > (100 * 60 * 60)) {
            return '>'
          } else if (this.timeRemain <= 0) {
            return '-'
          } else {
            return ''
          }
        } else {
          return ''
        }
      }
    },
    mounted() {
    },
    methods: {
    },
    i18n: {
      messages: {
        ja: {
        },
        en: {
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .timer {
    width: 100%;
    text-align: center;
    font-size: min(17vmin, 100px);
    color: #999;
    padding-right: 6vmin;
  }
</style>
