<template>
  <v-dialog
    v-model="dialog"
    overlay-color="#000000"
    overlay-opacity=".8"
    transition="dialog-bottom-transition"
    :fullscreen="bodyWidth < 700 || bodyHeight < 700"
  >
    <v-card
      v-if="schedule"
    >
      <v-app-bar
        :color="$vuetify.theme.isDark ? '' : 'primary'"
        dark
        :height="appBarHeight"
      >
        <v-btn icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>{{ schedule.label }}</v-toolbar-title>
      </v-app-bar>
      <v-list>
        <template
          v-for="item in items"
        >
          <v-list-item :key="item.id + 'list'">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>{{ item.icon }}</v-icon>
                {{ $t(item.id) }}
              </v-list-item-title>
            </v-list-item-content>
            <DetailDialogToggle v-if="item.id === 'toggle'" :labelName="$t(item.id)" />
            <DetailDialogLabel v-else-if="item.id === 'label'" :labelName="$t(item.id)" />
            <DetailDialogDays v-else-if="item.id === 'days'" :labelName="$t(item.id)" />
            <DetailDialogHours v-else-if="item.id === 'hours'" :labelName="$t(item.id)" />
            <DetailDialogMinutes v-else-if="item.id === 'minutes'" :labelName="$t(item.id)" />
          </v-list-item>
          <v-divider :key="item.id + 'divider'" />
        </template>
        <v-list-item class="buttons">
          <v-list-item-content>
            <DetailDialogDelete v-if="isEdit" />
          </v-list-item-content>
          <v-list-item-action-text>
            <v-btn
              text
              color="primary"
              @click="dialog = false"
            >{{ $t('dialog.close') }}</v-btn>
            <DetailDialogSave />
          </v-list-item-action-text>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import DetailDialogToggle from './DetailDialogToggle.vue'
  import DetailDialogLabel from './DetailDialogLabel.vue'
  import DetailDialogDays from './DetailDialogDays.vue'
  import DetailDialogHours from './DetailDialogHours.vue'
  import DetailDialogMinutes from './DetailDialogMinutes.vue'
  import DetailDialogDelete from './DetailDialogDelete.vue'
  import DetailDialogSave from './DetailDialogSave.vue'

  export default {
    data() {
      return {
        items: [{
          id: 'toggle',
          icon: 'mdi-volume-high',
        }, {
          id: 'label',
          icon: 'mdi-label',
        }, {
          id: 'days',
          icon: 'mdi-calendar',
        }, {
          id: 'hours',
          icon: 'mdi-clock',
        }, {
          id: 'minutes',
          icon: 'mdi-clock-outline',
        }]
      };
    },
    computed: {
      dialog: {
        get() { return this.schedule ? true : false },
        set() { this.$store.commit('setSchedule', null) },
      },
      isEdit() {
        return (_.find(this.schedules, ['id', this.schedule.id]))
      },
    },
    i18n: {
      messages: {
        ja: {
          toggle: 'ON / OFF',
          label: 'ラベル',
          days: '曜日',
          hours: '時',
          minutes: '分'
        },
        en: {
          toggle: 'ON / OFF',
          label: 'Label',
          days: 'Days',
          hours: 'Hours',
          minutes: 'Minutes'
        }
      }
    },

    components: { DetailDialogToggle, DetailDialogLabel, DetailDialogDays, DetailDialogHours, DetailDialogMinutes, DetailDialogDelete, DetailDialogSave },
  };
</script>


<style lang="scss" scoped>
  ::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-width: 600px !important;
  }
  .v-list-item__content {
    min-width: 60px;
  }
  @media (min-width: 500px) {
    .buttons {
      margin-top: 20px;
      ::v-deep .v-btn {
        margin-left: 10px;
        font-size: 1.2rem;
        padding: 20px 40px;
      }
    }
  }
</style>
