
const VER = 4.0;

export default Object.freeze({
	APP_ID: 'chime',
	VER: VER,
	LOCAL_DATA_STRUCTURE: {
		ver: VER,
		activated: new Date().getTime(),
		userId: '',
		isAdmin: false,
		data: {
      schedules: [],
      volume: 80,
      theme: 'system', // 'system', 'dark', 'light'
    },
	},
	URLS: {
		apis: ['https://api1.aroundit.net', 'https://api2.aroundit.net'],
		site: 'https://apps.wanakijiji.com',
    pwa: 'https://chime.aroundit.net',
		play: '',
		app: '',
	},
	API_KEYS: {
		admob: {
			android: 'ca-app-pub-3940256099942544/6300978111', // デモID
			ios: 'ca-app-pub-3940256099942544/2934735716', // デモID
		}
	},
  FIREBASE: {
    apiKey: "AIzaSyCaYaItJ4uQRvPbFJ5dtuXAUZGyGVN6Pho",
    authDomain: "chime-2021.firebaseapp.com",
    projectId: "chime-2021",
    storageBucket: "chime-2021.appspot.com",
    messagingSenderId: "257296747167",
    appId: "1:257296747167:web:3c4532d0a17422982670aa",
    measurementId: "G-V7WVWNKBEQ"
  },
	POST_ID: 97,
  SOURCE: {
    name: {
      ja: '',
      en: '',
    },
    url: {
      ja: '',
      en: '',
    },
  },
  THEMES: ['system', 'light', 'dark'],
  // 表示メニュー一覧
  MENU: {
    order: ['home', 'request', 'contact', 'review'], // 'home', 'request', 'contact', 'review', 'language'
    extras: [], // item objectを入れて
  },
  COLOR: {
    light: {
      primary: '#26A69A',
      primaryBG: '#26A69A',
      secondary: '#FFB300',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#004D40', // 暗めに設定
    },
    dark: {
      primary: '#26A69A',
      primaryBG: '#00695C', // 暗めに設定
      // primaryBG: '#004D40', // 暗めに設定
      secondary: '#FF6F00', // 暗めに設定
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#004D40', // 暗めに設定
    }
  },
  SCHEDULE: {
    id: null,
    label: '',
    days: _.fill(_.range(7), true),
    hours: _.fill(_.range(24), false),
    minutes: 0,
    soutn: 'bell', // 未使用
    toggle: true,
  },
  WEEKS: ['日', '月', '火', '水', '木', '金', '土'],
})
