<template>
  <fragment>
    
    <TheAppbar :title="isLargeScreen ? '' : $t('appName')">
      <v-btn
        icon
        @click="settingDialog = true"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </TheAppbar>
    
    <v-main>
      <v-list>
        <HomeItem
          v-for="schedule in schedules"
          :key="schedule.id"
          :item="schedule"
        ></HomeItem>
      </v-list>
    </v-main>

    <v-footer
      fixed
      :style="{marginLeft: isLargeScreen ? '280px' : 0}"
    >
      <HomeTimer />
    </v-footer>

    <HomeAdd />

    <DetailDialog />
    <HomePlayer />
    <HomeConfirmAutoplay />
    <HomeSettings />

  </fragment>
</template>

<script>
  import TheAppbar from '../commons/components/TheAppbar.vue'
  import DetailDialog from './DetailDialog.vue'
  import HomeItem from './HomeItem.vue'
  import HomeTimer from './HomeTimer.vue'
  import HomePlayer from './HomePlayer.vue'
  import HomeConfirmAutoplay from './HomeConfirmAutoplay.vue'
  import HomeSettings from './HomeSettings.vue'
  import HomeAdd from './HomeAdd.vue'

  export default {
    data() {
      return {
        name: 'home',
        dummySchedule: {
          id: 1, // not 0
          label: this.$t('label') + '1',
          days: [false, true, true, true, true, true, false],
          hours: [false, false, false, false, false, false, false, false, true, true, true, false, true, true, true, false, false, false, false, false, false, false, false, false], // 9, 10, 11, 13, 14, 15
          minutes: 25,
          toggle: true,
        },
      };
    },
    mounted() {
      // schedulesの読み込み
      if (! this.schedules.length) {
        this.schedules.push(this.dummySchedule)
        this.schedules = _.cloneDeep(this.schedules)
      }
    },
    i18n: {
      messages: {
        ja: {
          label: 'チャイム '
        },
        en: {
          label: 'bell '
        }
      }
    },
    components: { TheAppbar, DetailDialog, HomeItem, HomeTimer, HomePlayer, HomeConfirmAutoplay, HomeSettings, HomeAdd },

  };
</script>

<style lang="scss">
  .circle {
    font-size: 1.1rem;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    margin: 3px;
    background-color: #bbb;
    &.active {
      background-color: var(--v-primaryBG-base) !important;
    }
  }
  .theme--dark {
    .circle {
      background-color: #444;
    }
  }
</style>

<style lang="scss" scoped>
  .theme--light {
    .v-main {
      background-color: #f5f5f5;
    }
    .v-footer {
      background-color: #DDD;
    }
  }
  .v-list {
    background-color: transparent;
    padding: 2vmin 0 min(32vmin, 200px);
    max-width: 600px;
    margin: 0 auto;
  }
</style>
