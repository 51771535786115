<template>
  <fragment>
    <v-btn
      dark
      color="primaryBG"
      @click="save()"
    >{{ $t('dialog.save') }}</v-btn>
    
    <!-- <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>{{ $t('confirm') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="dialog = false"
          >{{ $t('dialog.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </fragment>
</template>

<script>
  export default {
    data() {
      return {
        // dialog: false
      };
    },
    computed: {
    },
    methods: {
      save() {
        const scheduleIndex = _.findIndex(this.schedules, ['id', this.schedule.id])
        if (scheduleIndex !== -1) {
          // 編集
          this.schedules[scheduleIndex] = this.schedule
        } else {
          // 新規追加
          this.schedules.push(this.schedule)
        }
        this.schedules = _.cloneDeep(this.schedules) // reactive
        this.$store.commit('setSchedule', null)
        // if (this.schedule.id) {
        // } else {
        //   this.dialog = true
        // }
      },
    },
    i18n: {
      messages: {
        // ja: {
        //   desc: '「曜日」と「時間」',
        // },
        // en: {
        //   desc: 'Are you sure you want to delete this schedule?',
        // }
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
