import Vue from 'vue'
import VueRouter from 'vue-router'
import _router from '../commons/_router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history', // PWA版のみ？
  routes: _.concat(_router, [
    // path: '/about',
    // name: 'About',
    // component: () => import('../commons/components/About.vue')
  ])
})

export default router
