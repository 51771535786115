<template>
  <v-list-item-action>
    <template
      v-for="(eachHours, i) in _.chunk(optionHours, 6)"
    >
      <div :key="i">
        <span
          v-for="hour in eachHours"
          :key="hour"
          class="hour circle"
          :class="{active: hours[hour]}"
          @click="toggle(hour)"
        >{{ hour }}</span>
      </div>
    </template>
  </v-list-item-action>
</template>

<script>

  export default {
    data() {
      return {
        optionHours: _.range(24),
      };
    },

    computed: {
      hours() {
        return _.get(this.schedule, 'hours') || []
      }
    },

    methods: {
      toggle(hour) {
        this.schedule.hours[hour] = ! this.schedule.hours[hour]
        this.schedule = _.cloneDeep(this.schedule) // reactive
      },
    },

    i18n: {
      messages: {
        ja: {
        },
        en: {
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .v-list-item__action {
    margin-left: 0 !important;
    div {
      display: flex;
    }
  }
  @media (min-width: 410px) {
    .circle {
      cursor: pointer;
      width: min(50px, 8.6vw);
      height: min(50px, 8.6vw);
    }
  }
</style>
