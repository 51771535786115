<template>
  <fragment>
    <v-dialog
      v-model="settingDialog"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-list>
            <v-subheader>
              <v-icon>mdi-brightness-6</v-icon>
              {{ $t('theme') }}
            </v-subheader>
            <v-list-item>
              <v-list-item-content>
                <!-- radio buttons -->
                <v-radio-group v-model="theme">
                  <v-radio
                    v-for="theme in optionTheme"
                    :key="theme"
                    :label="$t(theme)"
                    :value="theme"
                  ></v-radio>
                </v-radio-group>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>
              <v-icon>mdi-volume-high</v-icon>
              {{ $t('volume') }}
            </v-subheader>
            <v-list-item>
              <v-list-item-content>
                <!-- slider -->
                <v-slider
                  v-model="volume"
                  step="10"
                  max="100"
                  min="0"
                  thumb-label
                ></v-slider>
                <!-- player -->
                <v-btn
                  icon
                  x-large
                  color="primary"
                  class="player"
                  @click="playToggle()"
                >
                  <v-icon v-if="! isPlaying">mdi-play-circle-outline</v-icon>
                  <v-icon v-else>mdi-stop-circle-outline</v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="settingDialog = false"
          >{{ $t('dialog.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <audio id="testBell" :src="require('../assets/bell-jp.mp3')"></audio>
  </fragment>
</template>

<script>
  export default {
    data() {
      return {
        name: 'homeSettings',
        optionTheme: ['light', 'dark', 'system'],
        isPlaying: false,
        audio: null,
        stopTimeout: null
      }
    },
    mounted() {
      this.audio = document.getElementById('testBell')
      this.audio.volume = this.volume / 100
    },
    watch: {
      volume() {
        this.audio.volume = this.volume / 100
      },
      settingDialog() {
        if (! this.settingDialog) {
          this.audio.pause()
          this.audio.currentTime = 0
          this.isPlaying = false
        }
      }
    },
    methods: {
      playToggle() {
        clearTimeout(this.stopTimeout)
        if (this.isPlaying) {
          this.audio.pause()
          this.audio.currentTime = 0
        } else {
          this.audio.play()
          this.stopTimeout = setTimeout(() => {
            this.audio.pause()
            this.audio.currentTime = 0
            this.isPlaying = false
          }, 24 * 1000)
        }
        this.isPlaying = ! this.isPlaying
      }
    },
    i18n: {
      messages: {
        ja: {
          theme: 'テーマ',
          volume: '音量',
          light: 'ライト',
          dark: 'ダーク',
          system: 'システムデフォルト',
        },
        en: {
          theme: 'theme',
          volume: 'volume',
          light: 'light',
          dark: 'dark',
          system: 'system default',
        }
      }
    },

  };
</script>

<style lang="scss" scoped>
  .v-card {
    .v-icon {
      margin-right: 10px;
    }
    .v-list-item__content {
      overflow: visible;
    }
    .v-subheader {
      font-size: 1.4rem;
    }
    // radio
    .v-input--radio-group {
      margin: 0px 20px;
      .v-radio {
        margin-bottom: 12px !important;
      }
    }
    // player
    .player {
      margin: 0 0 22px 20px;
    }
  }
</style>
